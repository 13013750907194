import React from "react";
import { ResdiencyType } from "resdiency";
import "./css/index.css";
import { Link } from "react-router-dom";
type Props = {
  name: string;
  description: string;
};

const ResidencySidebar = ({ name, description }: Props) => {
  return (
    <div className="residency-sidebar">
      {name && description ? (
        <div className="text-wrapper">
          <p className="residency-name">{name} 작가</p>
          <div className="description-box">
            <div className="description">{description}</div>
          </div>
          <Link to={"/contactus"} className="purchase-btn">
            작품 구매 문의
          </Link>
        </div>
      ) : (
        <div className="resdiency-empty">
          <p className="title">작품 구매 문의</p>
          <p className="subtitle">{`작품을 쉽고 안전하게 소장할 수 있도록
Kunst EINS의 아트 컨설팅이 함께 합니다.`}</p>
          <Link to={"/contactus"} className="purchase-btn">
            작품 구매 문의
          </Link>
        </div>
      )}
    </div>
  );
};

export default ResidencySidebar;
