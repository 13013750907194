import React, { useCallback, useEffect, useState } from 'react'
import News from '../News'
import { NewsType } from 'news';
import { useNavigate } from 'react-router-dom';
import { UserAgentType } from 'common';
import { child, get, ref } from 'firebase/database';
import { db } from '../../../core/firebase';
type Props = {
  userAgent: UserAgentType;
}
const NewsContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState<NewsType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const navigate = useNavigate();
  const fetchData = async () => {
    const dbRef = ref(db);
    try {
      const snapshot = await get(child(dbRef, 'notice'));
      if (snapshot.exists()) {
        const fetchedData = snapshot.val();
        const projectArray = Object.keys(fetchedData).map(key => ({
          id: key,
          ...fetchedData[key],
        }));
        return projectArray;
      } else {
        console.log('데이터가 존재하지 않습니다.');
        return [];
      }
    } catch (error) {
      console.error('데이터 가져오기 오류:', error);
      return [];
    }
  }
  const Navigate = useCallback(
    (data: NewsType) => {
      const { id, title, content, img, timestamp } = data;
      navigate(`/news/${data.id}`, {
        state: {
          id,
          title,
          content,
          img,
          timestamp
        }
      })
    },
    [],
  )
  useEffect(() => {
    const getNotice = async () => {
      const data = await fetchData();
      setData(data);
    };
    getNotice();
  }, [])
  return (
    <News
      data={data}
      userAgent={userAgent}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      Navigate={Navigate}
    />
  )
}

export default NewsContainer;