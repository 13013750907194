import React from 'react'
import "./css/index.css";
type Props = {
  title: string;
  mainTitle: string | boolean;
  subtitle: string | boolean;
};
const AboutInfoCard = ({ title, subtitle, mainTitle }: Props) => {
  return (
    <div className="about-info-card">
      <div className='about-box'>
        <p className="about-title">{title}</p>
        <p className="about-maintitle">{mainTitle}</p>
        <p className="about-subtitle">{subtitle}</p>
      </div>
    </div>
  );
}

export default AboutInfoCard