import { createSlice } from "@reduxjs/toolkit";
import { ResidencyViewType } from "resdiency";

type StateType = {
  img: string;
  toast: string;
  residency: ResidencyViewType[];
};

const initialState: StateType = {
  img: "",
  toast: "",
  residency: [],
};
const configReducer = createSlice({
  name: "config",
  initialState,
  reducers: {
    ChangeImg: (state, action) => {
      console.log("action", action.payload);
      state.img = action.payload;
    },
    SetToastMsg: (state, action) => {
      state.toast = action.payload;
    },
    SetResidency: (state, action) => {
      state.residency = action.payload;
    },
  },
});

export const { ChangeImg, SetToastMsg, SetResidency } = configReducer.actions;

export default configReducer.reducer;
