import React from "react";
import "./css/index.css";
type Props = {
  title: string;
  subTitle: string;
  idx: number;
  currentIdx: null | number;
  SectionOpen: (idx: number) => void;
};
const AboutCard = ({
  SectionOpen,
  idx,
  title,
  subTitle,
  currentIdx,
}: Props) => {
  return (
    <div className="wrapper">
      <button className="about-card" onClick={() => SectionOpen(idx)}>
        <div className="about-box">
          <div className="aboutcard-title-wrapper">
            <p className="aboutcard-title fahkwang-text">{title}</p>
          </div>
          <div className="aboutcard-subtitle-wrapper">
            <p className="aboutcard-subtitle">{subTitle}</p>
            <div className={`down-btn ${currentIdx === idx && "active"}`}>
              <img
                src="/assets/about/down.png"
                srcSet="
                        /assets/about/down@2x.png 2x,
                        /assets/about/down@3x.png 3x,
                    "
                alt="down"
              />
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default AboutCard;
