import "./css/index.css";
import NewsList from "../../components/NewsList/NewsList";
import { NewsType } from "news";
import PageSelectorContainer from "../../components/common/PageSelector/container/PageSelectorContainer";
import { UserAgentType } from "common";

type Props = {
  data: NewsType[];
  currentPage: number;
  userAgent: UserAgentType;
  Navigate: (data: NewsType) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};
const News = ({
  data,
  Navigate,
  setCurrentPage,
  currentPage,
  userAgent,
}: Props) => {
  return (
    <main className="news-page">
      <div className="title-wrapper">
        <p className="title fahkwang-text">News</p>
      </div>
      <section className="news-section">
        <div className="news-lists-wrapper">
          <div className="news-title-wrapper">
            {userAgent !== "mb" ? (
              <>
                <div className="box">
                  <p className="group-text">제목</p>
                </div>
                <p className="title-text">일정</p>
              </>
            ) : (
              <p className="mb-title">제목</p>
            )}
          </div>
          <div className="news-lists">
            {data.map((item, idx) => (
              <NewsList key={idx} data={item} Navigate={Navigate} />
            ))}
          </div>
        </div>
        <div className="page-selector-btn">
          <PageSelectorContainer
            totalItems={data.length}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </section>
    </main>
  );
};

export default News;
