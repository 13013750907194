import AboutCard from "../AboutCard";
type Props = {
  title: string;
  currentIdx: null | number;
  subTitle: string;
  idx: number;
  SectionOpen: (idx: number) => void;
};
const AboutCardContainer = ({
  idx,
  title,
  subTitle,
  currentIdx,
  SectionOpen,
}: Props) => {
  return (
    <AboutCard
      idx={idx}
      title={title}
      subTitle={subTitle}
      currentIdx={currentIdx}
      SectionOpen={SectionOpen}
    />
  );
};

export default AboutCardContainer;
