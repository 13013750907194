import { useEffect, useState } from "react";
import SliderBanner from "../SliderBanner";
import { ProjectType } from "projectus";
import { Banner } from "banner";

type Props = {
  data: Banner[];
};

const SliderBannerContainer = ({ data }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const NextBtn = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  const PrevBtn = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, data.length]);

  return (
    <SliderBanner
      data={data}
      currentIndex={currentIndex}
      NextBtn={NextBtn}
      PrevBtn={PrevBtn}
    />
  );
};

export default SliderBannerContainer;
