import { useLocation } from 'react-router-dom'
import NewsDetail from '../NewsDetail'

const NewsDetailContainer = () => {
    const { state } = useLocation();
    console.log('location', state);
    return (
        <NewsDetail
            date={state.timestamp}
            title={state.title}
            img={state.img}
            content={state.content}
        />
    )
}

export default NewsDetailContainer