import React, { useEffect, useState } from "react";
import "./css/index.css";
import { UserAgentType } from "common";
import SliderBannerContainer from "../../components/common/SliderBanner/container/SliderBannerContainer";
import { Banner } from "banner";
type Props = {
  data: Banner[];
  userAgent: UserAgentType;
};
const Home = ({ data, userAgent }: Props) => {
  const [isBlind, setIsBlind] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsBlind(true);
    }, 4000);

    return () => { };
  }, []);

  return (
    <main className="home-page">
      <div className="home-top-wrapper">
        {isBlind ? (
          <SliderBannerContainer data={data} />
        ) : (
          <div className="line-container">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
            <div className="line line4"></div>
            <div className="line line5"></div>
            <div className="line-logo-wrapper">
              <div className="line-title">예술과 세상이 만나는 곳</div>
              <img src="/assets/home/animation-logo.svg" alt="" />
            </div>
          </div>
        )}
      </div>
      <div className="kunst-eins">
        <img
          src={`/assets/home/${userAgent}/homelogo.png`}
          srcSet={`
            /assets/home/${userAgent}/homelogo@2x.png 2x,
            /assets/home/${userAgent}/homelogo@3x.png 3x,
          `}
        />
      </div>
    </main>
  );
};

export default Home;

const bannerArr = [
  {
    id: "sdfsfss",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    host: "박물관",
    place: "대구",
    subject: "Project",
    period: {
      start: 1728866299381,
      finish: 1730558299000,
    },
  },
  {
    id: "sdfsfs13213s",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    host: "박물관",
    place: "대구",
    subject: "Project",
    period: {
      start: 1728866299381,
      finish: 1730558299000,
    },
  },
  {
    id: "sdfsf312312ss",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    host: "박물관",
    place: "대구",
    subject: "Project",
    period: {
      start: 1728866299381,
      finish: 1730558299000,
    },
  },
];
