import React from "react";
import "./css/index.css";
import ResidencySliderContainer from "../ResidencySlider/container/ResidencySliderContainer";
import { ResidencyViewType } from "resdiency";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
type Props = {
  data: {
    caption: string;
    img: string;
    timestamp: number;
    title: string;
  }[];
  name: string;
  ratio: number;
  idx: null | number;
  subject: string;
  CloseModal?: () => void;
  changeImg: string;
  ImageMinus: () => void;
  ImagePlus: () => void;
};
const ResidencyDetail = ({
  idx,
  data,
  changeImg,
  name,
  subject,
  ratio,
  ImageMinus,
  CloseModal,
  ImagePlus,
}: Props) => {
  return (
    <div
      className="residency-detail"
      onClick={() => CloseModal && CloseModal()}
    >
      <div className="detail-card" onClick={(event) => event.stopPropagation()}>
        <div className="box">
          <ResidencySliderContainer data={data} name={name} subject={subject} />
        </div>
        <button
          className="close-btn"
          onClick={() => CloseModal && CloseModal()}
        >
          <img src="/assets/header/close.svg" alt="" />
        </button>
        <div className="title-wrapper">
          <p className="title">{data[idx ? idx : 0].title}</p>
          <p className="caption">{data[idx ? idx : 0].caption}</p>
        </div>
        <div className="img-wrapper">
          {data && (
            <TransformWrapper
              centerOnInit={false}
              panning={{ disabled: false }}
              limitToBounds={false}
              doubleClick={{ disabled: true }}
            >
              <TransformComponent>
                <img
                  style={{
                    transform: `scale(${ratio})`,
                    transition: "transform 0.3s ease",
                  }}
                  src={`${changeImg ? changeImg : data[0].img}`}
                  alt=""
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
        <div className="glasses-wrapper">
          <button className="circle" onClick={ImagePlus}>
            <img src="/assets/common/plus-glasses.svg" alt="plus-glasses" />
          </button>
          <button className="circle" onClick={ImageMinus}>
            <img src="/assets/common/minus-glasses.svg" alt="minus-glasses" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResidencyDetail;
