import React from "react";
import Banner from "../Banner";
import { UserAgentType } from "common";
type Props = {
  userAgent: UserAgentType;
};
const BannerContainer = ({ userAgent }: Props) => {
  return <Banner userAgent={userAgent} />;
};

export default BannerContainer;
