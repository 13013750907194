import React from "react";
import "./css/index.css";
import { formatDate } from "../../lib/lib";
type Props = {
  date: number;
  title: string;
  img: string;
  content: string;
};

const NewsDetail = ({ date, title, img, content }: Props) => {
  return (
    <main className="news-detail-page">
      <section className="news-detail-section">
        <div className="news-detail-wrapper">
          <div className="title-wrapper">
            {/* <p className="group-text">{title}</p> */}
            <p className="title-text">{title}</p>
          </div>
          <div className="date">{formatDate(date)}</div>
          <div className="description">{content}</div>
        </div>
        <div className="img-wrapper">
          <img src={`${img}.png`} alt="news-detail-img" />
        </div>
      </section>
    </main>
  );
};

export default NewsDetail;
