import React from 'react'
import "./css/index.css";
type Props = {
    msg: string;
}
const ToastMsg = ({ msg }: Props) => {
    return (
        <div className='toast-msg-box'>
            <p className='msg'>{msg}</p>
        </div>
    )
}

export default ToastMsg