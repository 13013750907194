import React from "react";
import "./css/index.css";
type Props = {
  fahkwang: string;
  text: string;
};

const ProjectTitle = ({ fahkwang, text }: Props) => {
  return (
    <div className="project-title-wrapper">
      <p className="project-title-now fahkwang-text">{fahkwang}</p>
      <p className="project-title-text">{text}</p>
    </div>
  );
};

export default ProjectTitle;
