import React, { useCallback, useEffect, useRef, useState } from "react";
import ResidencyDetail from "../ResidencyDetail";
import { ResidencyViewType } from "resdiency";
type Props = {
  data: {
    caption: string;
    img: string;
    timestamp: number;
    title: string;
  }[];
  name: string;
  subject: string;
  changeImg: string;
  CloseModal?: () => void;
};


const ResidencyDetailContainer = ({
  name,
  subject,
  data,
  changeImg,
  CloseModal,
}: Props) => {
  const [ratio, setRatio] = useState<number>(1);

  const ImagePlus = useCallback(() => {
    setRatio((prevRatio) => Math.min(2, prevRatio + 0.25)); // 최대 2배까지 확대
  }, []);

  const ImageMinus = useCallback(() => {
    setRatio((prevRatio) => Math.max(0.5, prevRatio - 0.25)); // 최소 0.5배까지 축소
  }, []);


  return (
    <ResidencyDetail
      data={data}
      name={name}
      idx={0}
      subject={subject}
      changeImg={changeImg}
      ratio={ratio}
      ImagePlus={ImagePlus}
      ImageMinus={ImageMinus}
      CloseModal={CloseModal}
    />
  );
};

export default ResidencyDetailContainer;
