import React, { useEffect, useState } from 'react'
import Home from '../Home'
import { UserAgentType } from 'common';
import { child, get, ref } from 'firebase/database';
import { db } from '../../../core/firebase';
type Props = {
  userAgent: UserAgentType;
};
const HomeContainer = ({ userAgent }: Props) => {
  const [data, setData] = useState([]);
  const BannerData = async () => {
    const dbRef = ref(db);

    try {
      const snapshot = await get(child(dbRef, 'banner'));
      if (snapshot.exists()) {
        const bannerData = snapshot.val();
        console.log('데이터:', bannerData);
        return bannerData;
      } else {
        console.log('데이터가 존재하지 않습니다.');
      }
    } catch (error) {
      console.error('데이터 가져오기 오류:', error);
    }
  }
  useEffect(() => {
    const getBanner = async () => {
      const data = await BannerData();
      setData(data);
    }
    getBanner();
  }, [])
  return <Home data={data} userAgent={userAgent} />;
}

export default HomeContainer