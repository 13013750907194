import React, { useCallback, useEffect, useState } from "react";
import Residency from "../Residency";
import { UserAgentType } from "common";
import { ResdiencyType, ResidencyListType, ResidencyViewType } from "resdiency";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { child, get, ref } from "firebase/database";
import { db } from "../../../core/firebase";
import { useNavigate } from "react-router-dom";
import { ChangeImg, SetResidency } from "../../../store/config/config";
import useScrollRestoration from "../../../hooks/UseEnhancedScrollRestoration";
type Props = {
  userAgent: UserAgentType;
};
const ResidencyContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector<RootState, ResidencyViewType[]>(
    (state) => state.config.residency
  );
  const [residencyList, setresidencyList] = useState<ResdiencyType[]>([]);
  const changeImg = useSelector<RootState, string>((state) => state.config.img);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [selectedDetail, setSelectedDetail] = useState<number | null>(null);
  const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<ResdiencyType>({
    id: "",
    subject: "",
    name: "",
    list: [
      {
        title: "",
        caption: "",
        img: "",
        timestamp: 0,
      },
    ],
    timestamp: 0,
    category: "",
  });
  useScrollRestoration();

  const fetchData = async () => {
    const dbRef = ref(db);
    try {
      const snapshot = await get(child(dbRef, "regidency"));
      if (snapshot.exists()) {
        const fetchedData = snapshot.val();
        const projectArray = Object.keys(fetchedData).map((key) => ({
          id: key,
          ...fetchedData[key],
        }));
        return projectArray;
      } else {
        console.log("데이터가 존재하지 않습니다.");
        return [];
      }
    } catch (error) {
      console.error("데이터 가져오기 오류:", error);
      return [];
    }
  };
  const ImgCardClicked = useCallback(
    (crtData: ResidencyViewType, idx: number) => {
      setSelectedIdx(idx);
      if (userAgent !== "mb" && currentData.id !== data[idx].id) {
        const clone = residencyList
          .slice()
          .filter((item) => item.id === crtData.id)[0];
        setCurrentData((prevData) => ({
          ...prevData,
          ...clone,
        }));
      } else if (
        userAgent !== "mb" &&
        currentData.id === data[idx].id &&
        idx === selectedIdx
      ) {
        dispatch(ChangeImg(crtData.img));
        setIsDetailOpen((prev) => !prev);
      } else if (userAgent === "mb") {
        dispatch(ChangeImg(crtData.img));
        navigate(`/residency/detail/${data[idx].id}`, { state: data[idx] });
      }
    },
    [currentData, selectedIdx, residencyList, userAgent]
  );

  const CloseModal = useCallback(() => {
    setIsDetailOpen((prev) => !prev);
  }, [data, userAgent, selectedDetail]);
  useEffect(() => {
    const getResidency = async () => {
      const data = await fetchData();
      let arr: ResidencyViewType[] = [];
      data.forEach((item) => {
        const { name, category, subject, id } = item;
        if (item.list) {
          item.list.forEach((data: ResidencyListType) => {
            arr.push({ ...data, id, name, category, subject });
          });
        }
      });
      arr.sort((a, b) => b.timestamp - a.timestamp);
      dispatch(SetResidency(arr));
      setresidencyList(data);
    };
    getResidency();
  }, []);
  return (
    <Residency
      currentData={currentData}
      data={data}
      selectedIdx={selectedIdx}
      isDetailOpen={isDetailOpen}
      changeImg={changeImg}
      userAgent={userAgent}
      ImgCardClicked={ImgCardClicked}
      CloseModal={CloseModal}
    />
  );
};

export default ResidencyContainer;
