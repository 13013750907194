import React from 'react'
import "./css/index.css";
import AboutImg from '../../../../components/AboutImg/AboutImg';
import { UserAgentType } from 'common';
import AboutInfoCard from '../../../../components/common/AboutInfoCard/AboutInfoCard';
import ListCard from '../../../../components/ListCard/ListCard';
import { title } from 'process';
import { Link } from 'react-router-dom';
type Props = {
  userAgent: UserAgentType;
}
const Section03 = ({ userAgent }: Props) => {
  const listArr = [
    {
      title: "작품 구매",
      lists: [
        {
          title: '아트 컨설팅을 통한 차별화된 작품 컬렉션'
        },
        {
          title: "비용 절감"
        },
        {
          title: "경비 처리 및 절세 효과"
        }
      ]
    },
    {
      title: "전시 및 행사 기획 과정",
      lists: [
        {
          title: userAgent === "pc" || userAgent === "tablet" ?
            `<strong>작품의 가치 평가와 컬렉션 전략</strong>이 필요한 개인 콜렉터` :
            `<strong>작품의 가치 평가와 컬렉션 전략</strong>이 필요한 
    개인 콜렉터`
        },
        {
          title: userAgent === "pc" || userAgent === "tablet" ?
            `<strong>비용절감, 경비처리 등 투자 전략</strong>이 필요한 개인 콜렉터"` :
            `<strong>비용절감, 경비처리 등 투자 전략</strong>이 필요한 
    개인 콜렉터"`
        },
        {
          title: userAgent === "pc" || userAgent === "tablet" ?
            `<strong>기업 이미지 강화, 환경 조성</strong>의 공간 전략이 필요한 기업 및 단체` :
            `<strong>기업 이미지 강화, 환경 조성</strong>의 공간 전략이 
    필요한 기업 및 단체`
        }
      ]
    },
  ];
  return (
    <div className="about-section3">
      <div className="img-wrapper">
        {["section3-1", "section3-2", "section3-3"].map((item, idx) => (
          <AboutImg userAgent={userAgent} src={item} key={idx} />
        ))}
      </div>
      <div className="box-wrapper">
        <AboutInfoCard
          title="작품 구매"
          mainTitle={`예술과 즐거움을
이어주다.`}
          subtitle={`작품을 쉽고 안전하게 소장할 수 있도록
Kunst EINS의 아트 컨설팅이 함께합니다.`}
        />
        <div className="right-box">
          {listArr.map((item, idx) => (
            <ListCard key={idx} title={item.title} lists={item.lists} />
          ))}
          <div className="inquiry">
            <p className='inquiry-text'>
              작품 구매 문의 | kunsteins.kr@gmail.com
            </p>
            <button className='contactus-btn'>
              <Link to={'/contactus'} className='contactus-text'>
                Contact us
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section03