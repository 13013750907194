import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
type Props = {
  img: string;
  title: string;
  subtitle: string;
  userAgent: UserAgentType;
};

const DisplayImg = ({ img, title, subtitle, userAgent }: Props) => {
  return (
    <div className="display-img-card">
      <img
        src={`/assets/about/${userAgent}/${img}.png`}
        srcSet={`
                /assets/about/${userAgent}/${img}@2x.png 2x,
                /assets/about/${userAgent}/${img}@3x.png 3x,
            `}
        alt=""
      />
      <div className="info-wrapper">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </div>
    </div>
  );
};

export default DisplayImg;
