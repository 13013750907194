import React, { useCallback, useEffect, useState } from 'react'
import PageSelector from '../PageSelector'
type Props = {
    totalItems: number;
    currentPage: number;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}
const PageSelectorContainer = ({ totalItems, currentPage, setCurrentPage }: Props) => {
    const [pageNumbers, setPageNumbers] = useState<number[]>([]);
    const [visiblePages, setVisiblePages] = useState<number[]>([]);
    const itemsPerPage = 6;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const maxVisiblePages = 5;
    const GetNumberPage = useCallback(() => {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }, [totalPages]);
    const PageChange = (page: number) => {
        setCurrentPage(page);
    };

    const VisiblePages = useCallback(() => {
        const startPage = Math.max(
            1,
            Math.min(currentPage - Math.floor(maxVisiblePages / 2), totalPages - maxVisiblePages + 1)
        );
        const newVisiblePages = pageNumbers.slice(startPage - 1, startPage + maxVisiblePages - 1);
        setVisiblePages(newVisiblePages);
    }, [currentPage, maxVisiblePages, pageNumbers, totalPages]);

    useEffect(() => {
        const numbers = GetNumberPage();
        setPageNumbers(numbers);
    }, [GetNumberPage]);

    useEffect(() => {
        VisiblePages();
    }, [VisiblePages]);
    return (
        <PageSelector
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            visiblePages={visiblePages}
            totalPages={totalPages}
            PageChange={PageChange}
        />
    )
}

export default PageSelectorContainer