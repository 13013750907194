import React, { useCallback, useEffect, useState } from 'react'
import Project from '../Project'
import { UserAgentType } from 'common'
import { ProjectType } from 'projectus';
import { useNavigate } from 'react-router-dom';
import { child, get, ref } from 'firebase/database';
import { db } from '../../../core/firebase';
type Props = {
  userAgent: UserAgentType;
}
const ProjectContainer = ({ userAgent }: Props) => {
  const navigate = useNavigate();
  const [banner, setBanner] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ProjectType[]>([]);
  // const currentItems = data.slice(
  //   (currentPage - 1) * 6,
  //   currentPage * 6
  // );
  const ProjectData = async () => {
    const dbRef = ref(db);
    try {
      const snapshot = await get(child(dbRef, 'project'));
      if (snapshot.exists()) {
        const fetchedData = snapshot.val();
        const projectArray = Object.keys(fetchedData).map(key => ({
          id: key,
          ...fetchedData[key],
        }));
        return projectArray;
      } else {
        console.log('데이터가 존재하지 않습니다.');
        return [];
      }
    } catch (error) {
      console.error('데이터 가져오기 오류:', error);
      return [];
    }
  };

  const BannerData = async () => {
    const dbRef = ref(db);

    try {
      const snapshot = await get(child(dbRef, 'banner'));
      if (snapshot.exists()) {
        const bannerData = snapshot.val();
        console.log('데이터:', bannerData);
        return bannerData;
      } else {
        console.log('데이터가 존재하지 않습니다.');
      }
    } catch (error) {
      console.error('데이터 가져오기 오류:', error);
    }
  }

  const ProjectNavigate = useCallback(
    (data: ProjectType) => {
      navigate(`/project/${data.id}`, {
        state: data,
      })
    },
    [],
  )
  useEffect(() => {
    const getProjects = async () => {
      const data = await ProjectData();
      setData(data);
    };
    const getBanner = async () => {
      const data = await BannerData();
      setBanner(data);
    }
    getBanner();
    getProjects();
  }, []);
  console.log('data', banner)
  return (
    <Project
      userAgent={userAgent}
      totalItems={data.length}
      data={data}
      currentPage={currentPage}
      ProjectNavigate={ProjectNavigate}
      setCurrentPage={setCurrentPage}
      banner={banner}
    />
  );
}

export default ProjectContainer;

const projectImgArr = [
  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "test01",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    subject: `Project`,
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
  },
  {
    id: "sdfs",

    thumbnail: "test01",
    mainimg: "test02",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },
  {
    id: "dsfsfsd",
    thumbnail: "test03",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },
  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },
  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },

  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },
  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },
  {
    id: "dsfsfsd",
    thumbnail: "test01",
    mainimg: "mainimg",
    detailimages: ["test01", "test02", "test03"],
    title: "2024 대구박물관 기념 전시",
    date: "2024/12/12 ~ 2024/12/15",
    host: "대구박물관",
    location: "대구박물관",
    subject: "무한한 예술적 가능성을 품은 신예작가를 발굴",
  },

];