import React from "react";
import "./css/index.css";

type Props = {
  title: string;
  lists: {
    title: string | boolean;
  }[]
}
const ListCard = ({ title, lists }: Props) => {
  return (
    <div className="list-card">
      <div className="list-wrapper">
        <div className="title-wrapper">
          <p className="title">{title}</p>
        </div>
        <ol className="list-box">
          {lists.map((item, idx) => (
            <li
              key={idx}
              dangerouslySetInnerHTML={{
                __html:
                  typeof item.title === "string"
                    ? item.title
                    : String(item.title),
              }}
            />
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ListCard;
