import { formatDate } from "../../lib/lib";
import "./css/index.css";
import { NewsType } from "news";

type Props = {
    data: NewsType;
    Navigate: (data: NewsType) => void;
};

const NewsList = ({ data, Navigate }: Props) => {
    return (
        <div className="news-list-wrapper" onClick={() => Navigate(data)}>
            <div className="list-wrapper">
                <div className="box">
                    <p className="list-group">{data.title}</p>
                    {/* <p className="list-title">{data.title}</p> */}
                </div>
                <p className="list-date">{formatDate(data.timestamp)}</p>
            </div>
        </div>
    );
};

export default NewsList;
