import React from 'react'
import ProjectDetail from '../ProjectDetail'
import { UserAgentType } from 'common'
import { useLocation } from 'react-router-dom';
type Props = {
    userAgent: UserAgentType;
}
const ProjectDetailContainer = ({ userAgent }: Props) => {
    const location = useLocation();
    console.log('location.state', location.state)
    return (
        <ProjectDetail
            userAgent={userAgent}
            data={location.state}
        />
    )
}

export default ProjectDetailContainer