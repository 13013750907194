import { useCallback, useEffect, useRef } from "react";
import { useNavigationType } from "react-router-dom";

const useThrottle = () => {
  const isWaiting = useRef(false);

  /** 스크롤 위치를 기록하는 경우 delay 50이하일 때 가장 원활하여 50을 기본값으로 설정 */
  return useCallback(
    (callback: (...arg: any) => void, delay: number = 50) =>
      (...arg: any) => {
        if (!isWaiting.current) {
          callback(...arg);

          isWaiting.current = true;

          setTimeout(() => {
            isWaiting.current = false;
          }, delay);
        }
      },
    []
  );
};
const NAVIGATION_TYPE = {
  POP: "POP",
  PUSH: "PUSH",
  RELOAD: "RELOAD",
} as const;

const useScrollRestoration = () => {
  const navigationType = useNavigationType();
  const throttle = useThrottle();

  const SESSION_STORAGE_KEY = {
    SCROLL_Y: "SCROLL_Y",
  } as const;

  useEffect(() => {
    const rootRef = document.getElementById("root");
    const handleScrollWithThrottle = throttle(() => {
      if (rootRef) {
        sessionStorage.setItem(
          SESSION_STORAGE_KEY.SCROLL_Y,
          rootRef.scrollTop.toString()
        );
      }
    });

    rootRef?.addEventListener("scroll", handleScrollWithThrottle);

    return () => {
      rootRef?.removeEventListener("scroll", handleScrollWithThrottle);
    };
  }, []);

  useEffect(() => {
    const rootRef = document.getElementById("root");
    if (navigationType === NAVIGATION_TYPE.POP) {
      const savedScrollY = sessionStorage.getItem(SESSION_STORAGE_KEY.SCROLL_Y);

      if (savedScrollY && rootRef) {
        rootRef.scrollTop = parseInt(savedScrollY, 10);
      }
    }
  }, [navigationType]);
};

export default useScrollRestoration;
