import { UserAgentType } from 'common';
import "./css/index.css";
type Props = {
  userAgent: UserAgentType;
  src: string;
}
const AboutImg = ({ userAgent, src }: Props) => {
  return (
    <img
      className='about-section-img'
      src={`/assets/about/${userAgent}/${src}.png`}
      srcSet={`
            /assets/about/${userAgent}/${src}@2x.png 2x,    
            /assets/about/${userAgent}/${src}@3x.png 3x,    
        `}
      alt="about-img"
    />
  );
}

export default AboutImg