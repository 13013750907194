import React, { useCallback, useEffect, useState } from "react";
import About from "../About";
import { UserAgentType } from "common";
import {
  get,
  query,
  ref,
  limitToLast,
  orderByChild,
  startAt,
} from "firebase/database";
import { db } from "../../../core/firebase";
import { ResdiencyType } from "resdiency";
type Props = {
  userAgent: UserAgentType;
};
const AboutContainer = ({ userAgent }: Props) => {
  const [currentIdx, setCurrentIdx] = useState<null | number>(null);
  const [regidency, setregidency] = useState<ResdiencyType[]>([]);
  const SectionOpen = useCallback(
    (idx: number) => {
      setCurrentIdx((prev) => (prev === idx ? null : idx));
    },
    [currentIdx]
  );
  const GetRegidency = useCallback(async () => {
    const regidencyData = await get(
      query(
        ref(db, "regidency"),
        limitToLast(3),
        orderByChild("list"),
        startAt(1)
      )
    );

    if (regidencyData.exists()) {
      setregidency(Object.values(regidencyData.val()));
    }
  }, []);
  useEffect(() => {
    GetRegidency();

    return () => { };
  }, []);

  return (
    <About
      currentIdx={currentIdx}
      userAgent={userAgent}
      SectionOpen={SectionOpen}
      regidency={regidency}
    />
  );
};

export default AboutContainer;
