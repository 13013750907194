import React from "react";
import "./css/index.css";
import { ProjectType } from "projectus";
import { formatDate } from "../../lib/lib";
type Props = {
  data: ProjectType;
  ProjectNavigate: (data: ProjectType) => void;
};
const ProjectImgCard = ({ data, ProjectNavigate }: Props) => {
  return (
    <div onClick={() => ProjectNavigate(data)} className="project-img-card">
      <img
        className="project-img"
        src={data.detailimages[0]}
        alt="project-img"
      />
      <div className="info-wrapper">
        <p className="info-title">{data.title}</p>
        <p className="info-subtitle">{data.subject}</p>
        <p className="info-date">
          {`${formatDate(data.period.start)} ~ ${formatDate(
            data.period.finish
          )}`}
        </p>
      </div>
    </div>
  );
};

export default ProjectImgCard;
