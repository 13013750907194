import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import { UserAgentType } from "common";
import { useLocation } from "react-router-dom";
type Props = {
  userAgent: UserAgentType;
};
const HeaderContainer = ({ userAgent }: Props) => {
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);

  const MenuOpenClicked = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);
  useEffect(() => {
    console.log(location.pathname);
    if (location.pathname !== "/Residency") {
      document.getElementById("root")?.scrollTo(0, 0);
      console.log("돌아감");
    }
    setIsMenuOpen(true);
    return () => {};
  }, [location]);
  return (
    <Header
      userAgent={userAgent}
      isMenuOpen={isMenuOpen}
      MenuOpenClicked={MenuOpenClicked}
    />
  );
};

export default HeaderContainer;
