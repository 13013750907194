import React from "react";
import "./css/index.css";
import AboutImg from "../../../../components/AboutImg/AboutImg";
import { UserAgentType } from "common";
import AboutInfoCard from "../../../../components/common/AboutInfoCard/AboutInfoCard";
import AuthorImgCard from "../../../../components/AuthorImgCard/AuthorImgCard";
import { Link } from "react-router-dom";
import { ResdiencyType } from "resdiency";
type Props = {
  userAgent: UserAgentType;
  regidency: ResdiencyType[];
};
const Section02 = ({ userAgent, regidency }: Props) => {
  const imgArr = ["section2-1", "section2-2", "section2-3"];
  const circleArr = [
    {
      img: "circle1",
      text: `작가 발굴 및
영입`,
    },
    {
      img: "circle2",
      text: `맞춤형 전략 수립 
및 지원`,
    },
    {
      img: "circle3",
      text: `작가 브랜딩
프로그램`,
    },
    {
      img: "circle4",
      text: `다양한 전시 및 행
사 기회 제공`,
    },
    {
      img: "circle5",
      text: `효과적인 마케팅 
및 홍보`,
    },
    {
      img: "circle6",
      text: `지속적인
커리어 관리`,
    },
    {
      img: "circle7",
      text: `아트 네트워킹
기회 제공`,
    },
  ];
  const authorImgArr = [
    {
      author: "홍길동 작가",
      src: "display1-1",
    },
    {
      author: "김철수 작가",
      src: "display1-2",
    },
    {
      author: "김유리 작가",
      src: "display1-3",
    },
  ];
  const titleArr = {
    title: "아티스트 매니지먼트",
    mainTitle:
      userAgent === "pc" || userAgent === "tablet"
        ? `작가와 세상을
이어주다.`
        : "작가와 세상을 이어주다.",
    subTitle:
      userAgent === "pc"
        ? `kunst EINS는 무한한 예술적 가능성을 지닌 신예 작가들을 발굴하고,
그들의 예술적 첫 걸음을 지원합니다.

우리는 작가를 잘 이해하는 아트 파트너로서,작가의 가치를 
효과적으로 이끌어내는 매니지먼트 사로 활동합니다.
작가들이 예술 생태계에서 적응하고 
성장하는 과정을 함께합니다.`
        : userAgent === "tablet"
          ? `kunst EINS는 무한한 예술적 가능
성을 지닌 신예 작가들을 발굴하고, 
그들의 예술적 첫 걸음을 
지원합니다.

우리는 작가를 잘 이해하는 아트 파
트너로서, 작가의 가치를 효과적으
로 이끌어내는 매니지먼트 사로 
활동합니다. 작가들이 예술 생태계
에서 적응하고 성장하는 과정을 
함께합니다.`
          : `kunst EINS는 무한한 예술적 가능성을 지닌 신예 작가들을
발굴하고, 그들의 예술적 첫 걸음을 지원합니다. 
우리는 작가를 잘 이해하는 아트 파트너로서, 작가의 가치를 
효과적으로 이끌어내는 매니지먼트 사로 활동합니다. 
작가들이 예술 생태계에서 적응하고 
성장하는 과정을 함께합니다.`,
  };
  return (
    <div className="about-section2">
      <div className="img-wrapper">
        {imgArr.map((item, idx) => (
          <AboutImg userAgent={userAgent} src={item} key={idx} />
        ))}
      </div>
      <div className="box-wrapper">
        <AboutInfoCard
          title={titleArr.title}
          mainTitle={titleArr.mainTitle}
          subtitle={titleArr.subTitle}
        />
        <div className="right-box">
          <div className="management-procedure">
            <div className="title-box">매니지먼트 과정</div>
            <div className="circle-wrapper">
              {circleArr.map(({ text, img }, idx) => (
                <div key={idx} className="circle">
                  <div className="img-box">
                    <img
                      src={`/assets/about/pc/${img}.png`}
                      srcSet={`
                            /assets/about/pc/${img}@2x.png 2x,
                            /assets/about/pc/${img}@3x.png 3x,
                          `}
                      alt="circle-img"
                    />
                  </div>
                  <div className="circle-text">{text}</div>
                </div>
              ))}
            </div>
            <div className="author-img-wrapper">
              <div className="author-title-box">매니지먼트 작가 보러가기</div>
              <div className="author-imgs">
                <div className="img-box">
                  {regidency.map((item, idx) => (
                    <AuthorImgCard
                      key={idx}
                      userAgent={userAgent}
                      data={item}
                    />
                  ))}
                </div>
                <div className="link-wrapper">
                  <Link to={"/residency"} className="button">
                    더 많은 작가 만나보기
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section02;
