import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
type Props = {
  userAgent: UserAgentType;
};
const Banner = ({ userAgent }: Props) => {
  return (
    <div className="aboutus-banner">
      <p className="aboutus-banner-title">
        {userAgent === "pc" || userAgent === "tablet"
          ? `예술가와 세상이 만나는 곳
쿤스트아인스`
          : `예술가와 세상이 
만나는 곳
쿤스트아인스`}
      </p>
      <p className="aboutus-banner-subtitle">
        {userAgent === "pc" || userAgent === "tablet"
          ? `무한한 예술적 가능성을 품은 신예작가를 발굴하고 그들의 첫걸음을 함께 하는 일을 합니다. 
작가를 잘 이해하는 아트 파트너로 작가의 가치를 잘 이끌어내는 매니지먼트사로 
작가가 예술 생태계에서 적응하고 성장하는 가치있는 과정을 함께 합니다.`
          : `무한한 예술적 가능성을 품은 신예작가를 발굴하고 
그들의 첫걸음을 함께 하는 일을 합니다.
작가를 잘 이해하는 아트 파트너로
작가의 가치를 잘 이끌어내는 매니지먼트사로
작가가 예술 생태계에서 적응하고
성장하는 가치있는 과정을 함께 합니다.`}
      </p>
      <div className="line-container">
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
        <div className="line line4"></div>
        <div className="line line5"></div>
      </div>
    </div>
  );
};

export default Banner;
