import React, { useCallback, useState } from 'react'
import ContactUs from '../ContactUs'
import { UserAgentType } from 'common'
import { ContactusType } from 'contactus';
import { formatPhoneNumber } from '../../../lib/lib';
import { push, ref, set } from 'firebase/database';
import { db } from '../../../core/firebase';
import { useDispatch } from 'react-redux';
import { SetToastMsg } from '../../../store/config/config';
type Props = {
  userAgent: UserAgentType;
}
const ContactUsContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<ContactusType>({
    email: "",
    name: "",
    tel: "",
    content: "",
    category: "",
    timestamp: 0,
  })
  const [currentBtn, setCurrentBtn] = useState<null | number>(null);
  const [agreement, setAgreement] = useState<boolean>(false);
  const allValuesFiled = Object.entries(data)
    .filter(([key]) => key !== 'timestamp')  // timestamp 필드를 제외
    .every(([_, value]) => value.length > 0);
  const InquiryBtnClicked = useCallback(
    (idx: number, category: string) => {
      setCurrentBtn((prev) => prev === idx ? null : idx);
      setData((prev) => ({
        ...prev,
        category: category
      }))
    },
    [currentBtn, data],
  );
  const AgreementClicked = useCallback(
    () => {
      setAgreement((prev) => !prev)
    },
    [agreement],
  );

  const UpdateData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData((prev) => ({
        ...prev,
        [name]: name === "tel" ? formatPhoneNumber(value) : value,
      }))
    },
    [data],
  )

  const Submit = useCallback(
    async () => {
      try {
        const inquiryRef = ref(db, 'inquiry');
        const newInquiryRef = push(inquiryRef);

        await set(newInquiryRef, {
          email: data.email,
          name: data.name,
          tel: data.tel,
          content: data.content,
          category: data.category,
          timestamp: new Date().getTime()
        });
        dispatch(SetToastMsg("문의가 등록되었습니다."))
        setTimeout(() => {
          dispatch(SetToastMsg(""));
        }, 3000);
        setData({
          email: "",
          name: "",
          tel: "",
          content: "",
          category: "",
          timestamp: 0
        })
        setAgreement(false)
        setCurrentBtn(null)
      } catch (error) {
        console.error('fb err:', error);
      }
    },
    [data]
  );

  const KaKaoBtnClick = useCallback(
    () => {
      window.location.href = "http://pf.kakao.com/_Aaxlln";
    },
    [],
  )

  return (
    <ContactUs
      userAgent={userAgent}
      currentBtn={currentBtn}
      agreement={agreement}
      allValuesFiled={allValuesFiled}
      data={data}
      InquiryBtnClicked={InquiryBtnClicked}
      AgreementClicked={AgreementClicked}
      UpdateData={UpdateData}
      Submit={Submit}
      KaKaoBtnClick={KaKaoBtnClick}
    />
  )
}

export default ContactUsContainer