import React from 'react'
import ToastMsg from '../ToastMsg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'

const ToastMsgContainer = () => {
    const msg = useSelector<RootState, string>((state) => state.config.toast)
    return (
        <ToastMsg msg={msg} />
    )
}

export default ToastMsgContainer