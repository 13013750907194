import React from "react";
import "./css/index.css";
import { UserAgentType } from "common";
import { ProjectType } from "projectus";
import ProjectDetailTitle from "../../components/ProjectDetailTitle/ProjectDetailTitle";
type Props = {
  data: ProjectType;
  userAgent: UserAgentType;
};
const ProjectDetail = ({ userAgent, data }: Props) => {
  return (
    <main className="project-detail-page">
      <section className="project-detail-section">
        <div className="left-box">
          <ProjectDetailTitle
            title={data.title}
            subject={data.subject}
            img={data.mainimg}
            period={data.period}
            place={data.place}
            host={data.host}
            userAgent={userAgent}
          />
        </div>
        <div className="right-box">
          <div className="img-box">
            {data?.detailimages?.map((item, idx) => (
              <img key={idx} src={item} alt="project-detail-img" />
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default ProjectDetail;
