import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./core/core.css";
import HeaderContainer from "./components/Header/container/HeaderContainer";
import HomeContainer from "./pages/Home/container/HomeContainer";
import AboutContainer from "./pages/About/container/AboutContainer";
import ProjectContainer from "./pages/Project/container/ProjectContainer";
import ResidencyContainer from "./pages/Residency/container/ResidencyContainer";
import NewsContainer from "./pages/News/container/NewsContainer";
import ContactUsContainer from "./pages/ContactUs/container/ContactUsContainer";
import { useEffect, useState } from "react";
import { UserAgentType } from "common";
import NewsDetail from "./pages/NewsDetail/NewsDetail";
import NewsDetailContainer from "./pages/NewsDetail/container/NewsDetailContainer";
import ProjectDetailContainer from "./pages/ProjectDetail/container/ProjectDetailContainer";
import { RootState } from "./store";
import { useSelector } from "react-redux";
import ToastMsgContainer from "./components/common/ToastMsg/container/ToastMsgContainer";
import ResidencyMobileContainer from "./pages/Residency/container/RegidencyMobileContainer";
function App() {
  const msg = useSelector<RootState, string>((state) => state.config.toast);

  const [userAgent, setUserAgent] = useState<UserAgentType>("pc");
  useEffect(() => {
    function change(e: any) {
      const width = e.target.innerWidth;
      if (width < 1440 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1439 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }

    window.addEventListener("resize", change);
    return () => {
      window.removeEventListener("resize", change);
    };
  }, [userAgent]);
  useEffect(() => {
    function change() {
      const width = window.innerWidth;
      if (width < 1440 && width > 767 && userAgent !== "tablet") {
        setUserAgent("tablet");
      }
      if (width < 768 && userAgent !== "mb") {
        setUserAgent("mb");
      }
      if (width > 1439 && userAgent !== "pc") {
        setUserAgent("pc");
      }
    }

    change();

    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <HeaderContainer userAgent={userAgent} />
      <Routes>
        <Route path="/" element={<HomeContainer userAgent={userAgent} />} />
        <Route
          path="/about"
          element={<AboutContainer userAgent={userAgent} />}
        />
        <Route
          path="/project"
          element={<ProjectContainer userAgent={userAgent} />}
        />
        <Route
          path="/project/:id"
          element={<ProjectDetailContainer userAgent={userAgent} />}
        />
        <Route
          path="/residency"
          element={<ResidencyContainer userAgent={userAgent} />}
        />
        <Route
          path="/residency/detail/:id"
          element={<ResidencyMobileContainer userAgent={userAgent} />}
        />
        <Route path="/news" element={<NewsContainer userAgent={userAgent} />} />
        <Route path="/news/:id" element={<NewsDetailContainer />} />
        <Route
          path="/contactus"
          element={<ContactUsContainer userAgent={userAgent} />}
        />
      </Routes>
      {msg.length > 0 && <ToastMsgContainer />}
    </BrowserRouter>
  );
}
export default App;
